import React from 'react';
import './styles.scss';
import { linkify } from '../../utils/urlHelper';
import Icon from '../../components/Icon';

const Header = () => {
    return (
        <div
            data-e2e='header-container'
            className='trevenan-header d-flex justify-content-between'
        >
            <Icon
                name='trevenan-logo'
                width={137}
                height={43}
                className='trevenan-logo'
            />
            <div className='trevenan-link'>{linkify('HOME')}</div>
        </div>
    );
};

export default Header;
