import { BRAND_SPECIFIC_MESSAGES, CORE_MESSAGES } from './errorMessaging';
import { SUCCESS_SCREEN_CONFIG } from './successScreenConfig';
import { INSURANCE_SCREEN_CONFIG } from './insuranceScreenConfig';

const DRUG_NAME = 'trevenan';

export const brandConfigTrevenan = {
    colors: {
        primary: '#57aff8',
        ringColors: {
            first: '#A3BFFF',
            second: '#66bb6a'
        },
        fieldBorder: '#57aff8'
    },
    drugName: DRUG_NAME,
    title: 'Trevenan',
    features: {
        privacyPolicyLink: { isEnabled: true },
        showBottomDisclaimer: true
    },
    drugOptions: [
        {
            name: DRUG_NAME,
            ndc: '00456141030',
            quantityDespensed: 30,
            daysSupply: 30,
            uom: 'EA'
        }
    ],
    errorMessages: {
        brandSpecificMessages: BRAND_SPECIFIC_MESSAGES,
        coreMessages: CORE_MESSAGES
    },
    successScreen: SUCCESS_SCREEN_CONFIG,
    insuranceScreen: INSURANCE_SCREEN_CONFIG
};
