const BRAND_ERRORS = {
    preAuth: { componentName: 'TrevenanPreAuth' },
    general: { componentName: 'TrevenanGeneral' },
    notCovered: {
        componentName: 'TrevenanNotCovered'
    },
    network:
        'We could not retrieve your price for TREVENAN due to a network error with your insurance company. Please try again later.'
};

export const BRAND_SPECIFIC_MESSAGES = {
    rejectCodesPriority: ['75', 'MR', '70', '65', '60', '66'],
    phone: 'XXX-XXX-XXXX',
    messageWord: 'XXXXX',
    messages: {
        75: {
            commercial: BRAND_ERRORS.preAuth,
            government: BRAND_ERRORS.notCovered
        },
        MR: {
            commercial: BRAND_ERRORS.preAuth,
            government: BRAND_ERRORS.notCovered
        },
        70: {
            commercial: BRAND_ERRORS.preAuth,
            government: BRAND_ERRORS.notCovered
        },
        AJ: {
            commercial: BRAND_ERRORS.preAuth,
            government: BRAND_ERRORS.notCovered
        },
        R6: {
            commercial: BRAND_ERRORS.preAuth,
            government: BRAND_ERRORS.notCovered
        },
        76: {
            commercial: BRAND_ERRORS.preAuth,
            government: BRAND_ERRORS.notCovered
        },
        88: {
            commercial: BRAND_ERRORS.preAuth,
            government: BRAND_ERRORS.notCovered
        },
        608: {
            commercial: BRAND_ERRORS.preAuth,
            government: BRAND_ERRORS.notCovered
        },
        A5: BRAND_ERRORS.notCovered,
        79: 'We could not retrieve your cost for TREVENAN because you recently filled the medication.',
        69: BRAND_ERRORS.general,
        '09': BRAND_ERRORS.general,
        50: BRAND_ERRORS.general,
        65: BRAND_ERRORS.general,
        85: BRAND_ERRORS.general,
        90: BRAND_ERRORS.network,
        91: BRAND_ERRORS.network,
        92: BRAND_ERRORS.network,
        95: BRAND_ERRORS.network,
        96: BRAND_ERRORS.network,
        97: BRAND_ERRORS.network,
        98: BRAND_ERRORS.network,
        99: BRAND_ERRORS.network,
        M3: BRAND_ERRORS.network,
        MZ: BRAND_ERRORS.network,
        NN: BRAND_ERRORS.network,
        default: BRAND_ERRORS.general
    }
};
