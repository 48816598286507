import React from 'react';
import { Trevenan } from 'trucheck-widget';

import { Header, ContentLayout, Footer } from '../../Layout';

import '../../styles.css';
import { apiConfig, brandConfigTrevenan } from './../../config';

const Home = () => {
    return (
        <div className='trevenan-main'>
            <Header />
            <ContentLayout>
                <Trevenan
                    apiUrl={apiConfig.apiBaseUrl}
                    authConfig={{
                        publicAccessToken:
                            apiConfig.authConfig.publicAccessToken
                    }}
                    brandConfig={brandConfigTrevenan}
                    copayCardUrl={apiConfig.copayCardUrl}
                />
            </ContentLayout>
            <Footer />
        </div>
    );
};

export default Home;
